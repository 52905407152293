import React from 'react'
import Simple from './ReactiveCard/src/examples/Simple'
import './FullBlog.css' // Import the CSS file

export const FullBlog = () => {
  return (
    <div className="full-blog-container">
      <Simple />
    </div>
  )
}
